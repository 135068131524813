import React from "react";
import Helmet from "react-helmet";
import { graphql } from "gatsby";
import ContactBar from "../../components/ContactBar";
import SingleImage from "../../library/SingleImage";
import Layout from "../../components/Layout";

const EndometriosePage = (props) => (
  <Layout location={props.location}>
    <div className="page offer">
      <Helmet>
        <title>Endometriose</title>
        <meta
          name="description"
          content="Die Endometriose bedingten Menstruationsschmerzen können mit Akupunktur und chinesischen Kräutern sowie Moxibustion behandelt werden"
        />
        <meta property="og:title" content="Endometriose" />
        <meta
          property="og:description"
          content="Die Endometriose bedingten Menstruationsschmerzen können mit Akupunktur und chinesischen Kräutern sowie Moxibustion behandelt werden"
        />
      </Helmet>
      <ContactBar className="large-screen" />
      <div className="container">
        <h1>Endometriose</h1>
        <SingleImage image={props.data.endometriose.fluid} />
        <p>
          Endometriose ist auch in der heutigen Zeit noch eine Diagnose, welche
          erst nach langem und starkem Leidensdruck oder bei unerfülltem
          Kinderwunsch gestellt wird, da es für eine klare Diagnosestellung eine
          Operation bedarf.
        </p>
        <p>
          Die Behandlung mit TCM kann auch nur bei Verdacht und ohne klare
          Diagnosestellung mittels Operation durchgeführt werden, da sie sich
          nach den Symptomen richtet und nicht nach dem Namen der Erkrankung.
        </p>
        <p>
          Die Endometriose bedingten Menstruationsschmerzen können mit
          Akupunktur und chinesischen Kräutern sowie Moxibustion behandelt
          werden.
        </p>
        <p>
          Da man inzwischen weiss, das Endometriose eine Störung der
          Eizellreifung herrufen kann, ist auch sehr oft der Kinderwunsch der
          Frauen betroffen. In diesem Fall kann die TCM die Qualität und Reifung
          der Eizelle unterstützen und zu einer Schwangerschaft verhelfen.
        </p>
        <div className="cta">
          <p>Buchen Sie noch heute einen Termin!</p>
          <a
            className="button"
            href="https://www.coachfrog.ch/de/fachperson/paola-hoffmann/?bookingOpen=1"
            target="_blank"
            rel="noopener noreferrer"
          >
            Online buchen
          </a>
        </div>
      </div>
    </div>
  </Layout>
);

export default EndometriosePage;

export const query = graphql`
  query EndometrioseImageQuery {
    endometriose: imageSharp(
      fluid: { originalName: { regex: "/endometriose/" } }
    ) {
      fluid(maxHeight: 580, cropFocus: ENTROPY) {
        ...GatsbyImageSharpFluid
      }
    }
  }
`;
